import {useEffect, useState, SetStateAction, Dispatch} from 'react'

export default function useLocalStorage<T>(key : string, initialValue : T) : [T, Dispatch<SetStateAction<T>>] {
  const [storedValue, setStoredValue] = useState<T>(initialValue)

  useEffect(() => {
    try {
      setStoredValue(JSON.parse(window.localStorage.getItem(key)))
    } catch { }
  }, [key])

  useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(storedValue))
    } catch { }
  }, [storedValue, key])

  return [storedValue, setStoredValue]
}
