import {useState, useEffect} from 'react'

export default function useFirstRender(delay?: number) : boolean {
  const [firstRender, setFirstRender] = useState(true)

  useEffect(() => {
    if(!delay){
      setFirstRender(false)
      return
    }

    const t = setTimeout(() => {
      setFirstRender(false)
    }, delay)
    return () => clearTimeout(t)
  }, [delay])

  return firstRender
}
