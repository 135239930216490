import type { AppProps, AppContext as NextContext } from 'next/app'
import Script from 'next/script'

import Link from 'next/link'
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion'

import useFirstRender from '~/hooks/useFirstRender'

import '~/styles/app.css'
import '~/styles/nav.css'
import '~/styles/post.css'

import RSS from '~/icons/rss.svg'
import Moon from '~/icons/moon.svg'
import useLocalStorage from '~/hooks/useLocalStorage'

import useStyleTransition from '~/hooks/useStyleTransition'

const easing = [0.175, 0.85, 0.42, 0.96]

export default function App({Component, pageProps, router} : AppProps) {
  const [darkTheme, setDarkTheme] = useLocalStorage('ashe_darktheme', false)
  const prefersReducedMotion = useReducedMotion()
  const isFirstRender = useFirstRender()
  useStyleTransition();

  const {asPath: route} = router
  const headerIsExpanded = route === '/'

  const page = () => (
    <motion.div className="contentContainer" key={route} initial="initial" animate="enter" exit="exit" variants={{
      initial: (prefersReducedMotion) ? {y: 0, opacity: 0} : { y : 10, opacity: 0},
      enter:   { y:  0, opacity: 1, transition: { duration: 0.7, ease: easing } },
      exit:    { y:  0, opacity: 0, transition: { duration: 0.2, ease: easing } }
    }}>
      <Component {...pageProps}/>
    </motion.div>
  )

  return (
    <>
      <div className="root">
        <div className={`color ${headerIsExpanded ? 'expanded' : ''} ${darkTheme ? 'dark' : ''}`}>
          <div className="nav">
            <Link href="/"><a className={`homeLink ${route === '/' ? 'hidden' : ''}`}>ashe.gay</a></Link>
            <span>
              {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
              <a aria-label="RSS Feed" href="/rss.xml" target="_blank" className="navButton"><RSS/></a>
              <button aria-label="Dark Mode Toggle" className="navButton" onClick={() => setDarkTheme(current => !current)}><Moon/></button>
            </span>
          </div>
          <AnimatePresence initial={isFirstRender ? false : undefined} exitBeforeEnter>
            {headerIsExpanded && page()}
          </AnimatePresence>
        </div>
        <div className="background">
          <AnimatePresence initial={isFirstRender ? false : undefined} exitBeforeEnter>
            {!headerIsExpanded && page()}
          </AnimatePresence>
        </div>
      </div>
      <Script strategy="afterInteractive" data-website-id="9e990037-e995-4bde-81b0-76e782355f14" src="https://analytics.tempest.dev/umami.js"/>
    </>
  )
}
